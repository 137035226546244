<template lang="pug">
q-footer.bg-positive.text-secondary.q-pb-md(:style="noJs ? 'position: relative' : ''" itemscope itemtype="http://schema.org/WPFooter")
    .wrapper
        .row.justify-between.q-py-xl
            .col-3.column
                .font-18.text-weight-regular Служба заказов
                .text-secondary.q-mt-sm
                    | C 8.00 до 22.00 по мск.
                .q-ma-none.q-mt-sm
                    a.link.font-22.text-secondary.text-weight-bold(href="tel:+78002005400" rel="nofollow") +7 (800) 200-5-400
                .text-secondary
                    | (Звонок бесплатный)
                .q-ma-none.q-mt-lg.text-weight-medium(itemprop="copyrightHolder" content="ООО «АРТЭС»")
                    .text-caption.font-12
                        | ООО «АРТЭС» ОГРН: 1073130003980;
                        br
                        | Лицензия №Л042-01154-31/00561669
                        br
                        | от 01.12.2020 г.,
                        br
                        | Разрешение №ДТ-31-000176
                        br
                        | от 08.09.2020 г.
            .row.justify-between(
                :style="widthVindow ? '' : 'display: block'"
                :class="widthVindow ? 'col-6' : 'col-5'"
            )
                div(v-for="value of subsections")
                    .font-18.text-weight-bold.q-mt-xs {{ value.label }}
                    ul
                        li.q-pa-none(v-for="(item, index) in value.items")
                            router-link.font-14.text-weight-regular(
                                :to="city ? `/${city.slug}/${item.route}` : `/${item.route}`"
                                :title="`${item.label}`"
                                itemprop="text" 
                                :content="`${item.label}`"
                                v-if="!item.link")
                                span(v-html="item.label")
                            a.font-14.text-weight-regular(
                                v-else
                                :href="item.link" target="_blank" rel="nofollow")
                                span(v-html="item.label")
                    .row.q-mt-md(v-if="value.slot")
                        .font-18.text-weight-bold.text-secondary
                            | Мы в соцсетях
                        .q-ml-md
                            a.q-mr-md(href="https://vk.com/etabl" target="_blank" rel="nofollow")
                                q-icon(
                                    name="etabl:vk-round"
                                    size="md"
                                    color="secondary")
                            a(href="https://t.me/eTabl_eBot" target="_blank" rel="nofollow" @click="goToBot")
                                q-icon(
                                    name="etabl:telegram"
                                    size="md"
                                    color="secondary")
            .offset-1(
                :class="widthVindow ? 'col-2' : 'col-3'"
            )
                .q-pt-xs.font-16.text-weight-bold
                    | Скачайте мобильное приложение
                .q-mt-sm.q-mb-md.row(style="gap:0 16px;")
                    a(href="https://play.google.com/store/apps/details?id=artes.tabletochka" target="_blank" rel="nofollow")
                        q-icon(
                            name="etabl:google-play"
                            size="md"
                            color="secondary")
                    a(href="https://apps.apple.com/ru/app/etabl/id1542449495" target="_blank" rel="nofollow")
                        q-icon(
                            name="etabl:app-store"
                            size="md"
                            color="secondary")
                    a(href="https://appgallery.huawei.com/app/C107603961" target="_blank" rel="nofollow")
                        q-icon(
                            name="etabl:app-gellary"
                            size="md"
                            color="secondary")
                    a(href="https://apps.rustore.ru/app/artes.tabletochka" target="_blank" rel="nofollow")
                        q-icon(
                            name="etabl:ru-store"
                            size="md"
                            color="secondary")
                .flex
                    object(
                        type="image/svg+xml"
                        data="/qr-app.svg"
                        width="170" height="170"
                    )
        .row.q-mb-xl
            .col-12
                p
                    | © 2024 Все права защищены. <a href="https://www.sft-art.ru/" target="_blank" rel="nofollow">СофтАрт</a> - разработчик сайтов и приложений. Любая информация, размещенная на сайте, не является публичной офертой.
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';

export default {
    name: 'FooterDesktop',
    setup() {
        const store = useStore();

        const noJs = ref(true);

        // город пользователя
        const city = computed(() => store.getters['cityForm/CITY']);

        const widthVindow = ref(false);

        const subsections = {
            services: {
                label: 'О компании',
                items: [{
                    label: 'О компании',
                    route: 'about',
                }, {
                    label: 'Вакансии',
                    route: 'vacancies',
                }, {
                    label: 'Контакты',
                    route: 'contacts',
                }, {
                    label: 'Проекты',
                    route: 'projects',
                }, {
                    label: 'Зарабатывайте с<br>eTabl',
                    route: 'partneram',
                }, {
                    label: 'Карта сайта',
                    route: 'sitemap',
                }],
            },
            sections: {
                label: 'Покупателю',
                items: [{
                    label: 'Отследить заказ',
                    link: 'https://t.me/eTabl_eBot',
                }, {
                    label: 'Акции',
                    route: 'marketing-list',
                }, {
                    label: 'Статьи',
                    route: 'articles',
                }, {
                    label: 'Личный кабинет',
                    route: 'personal-area',
                }, {
                    label: 'Адреса аптек',
                    route: 'drugstores',
                }, {
                    label: 'Вопросы и ответы',
                    route: 'faq',
                }, {
                    label: 'Как сделать заказ',
                    route: 'how-to-order',
                }, {
                    label: 'Доставка и оплата',
                    route: 'shipping',
                }],
            },
            handbook: {
                label: 'Правовая информация',
                items: [{
                    label: 'Программа лояльности',
                    route: 'loyalty',
                }, {
                    label: 'Пользовательское соглашение',
                    route: 'agreement',
                }, {
                    label: 'Политика конфиденциальности',
                    route: 'policy',
                }, {
                    label: 'Продажа дистанционным способом',
                    route: 'selling-remotely',
                }, {
                    label: 'Согласие на обработку персональных данных',
                    route: 'person-data',
                }],
                slot: true,
            },
        };

        const goToBot = () => {
            if (process.env.CLIENT) {
                // window._paq.push(['trackGoal', 19]);
            };
        };

        onMounted(async () => {
            noJs.value = false;
            store.commit('device/SET_NO_JS', false);

            if(process.env.CLIENT && window.innerWidth >= 1280) widthVindow.value = true;
        });

        return {
            subsections,
            noJs,
            city,
            goToBot,
            widthVindow,
        };
    },
};
</script>
<style lang="scss" scoped>
a,
ul li a {
    text-decoration: none;
    color: $secondary;
}

ul {
    padding: 0;

    li {
        list-style: none;
        padding: 4px 0;
    }
}
</style>
